/**
 * Implements LaunchDarkly feature flagging for our NextJS app.
 *
 * I initially tried to use LaunchDarkly's react-web client SDK here,
 * to match our native implementation and the docs[1] - but Next was throwing
 * hydration errors about the client-side and server-side HTML being different.
 * I was quite possibly "holding it wrong" - but at some point, one must go do
 * real work, so I implemented the below using the lower-level client SDK and
 * moved on. Ideally, we figure out what's going wrong with the react-web
 * SDK someday, and simplify this. - @will
 *
 * [1]: https://docs.launchdarkly.com/guides/infrastructure/nextjs#which-sdk-do-i-use
 */
import type { LDContext } from "@launchdarkly/js-client-sdk-common";
import { type LDClient, initialize } from "launchdarkly-js-client-sdk";

import type { Flag } from "@medbillai/utils/flag";

import {
  type FlagProviderProps,
  type TypeOfResult,
  ldLogger,
  retryIdentify,
} from "./shared";

/**
 * The LaunchDarkly client-side SDK key. We take this from a global instead of
 * FlagProviderProps because on web, FlagProvider wouldn't be initialized until
 * after AuthGate tries to identify the user.
 */
const clientSideId = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID;
let client: LDClient | undefined;

/** A no-op react component for the web, to match our react-native interface. */
export const FlagProvider = ({ children }: FlagProviderProps) => children;

export const launchDarklyIdentify = (
  context: LDContext | ((prevContext?: LDContext) => LDContext),
) => {
  console.debug("launchDarklyIdentify input context", context);
  const existingContext = client ? client.getContext() : undefined;
  const newContext =
    typeof context === "function"
      ? context(existingContext as LDContext | undefined)
      : context;
  console.debug("launchDarklyIdentify new context", newContext);

  // Lazily initialize the client with the sdkKey and context, if needed.
  if (!client) {
    if (!clientSideId) {
      console.warn("No client or client-side ID, skipping identify");
      return;
    }

    console.log(
      "Initializing client with clientSideId and context:",
      clientSideId,
      newContext,
    );
    client = initialize(clientSideId, newContext, {
      logger: ldLogger,
      application: { id: "medbillai-next" },
    });
  }

  // const-assign the client so that typescript doesn't worry about mutations
  // happening before the callback.
  const c = client;
  retryIdentify(async () => {
    await c.identify(newContext);
  });
};

const forceType =
  <T>(typeofString: TypeOfResult) =>
  (flag: Flag, defaultValue: T) => {
    if (!client) {
      console.warn(
        "No LD client available, returning defaultValue:",
        defaultValue,
      );
      return defaultValue;
    }

    const out = client.variation(flag as string, defaultValue) as unknown;

    if (typeof out !== typeofString) {
      console.warn(
        "Type mismatch: expected",
        typeofString,
        "but got",
        typeof out,
        "returning defaultValue:",
        defaultValue,
      );
      return defaultValue;
    }
    return out as T;
  };

export const boolVariation = forceType<boolean>("boolean");
export const stringVariation = forceType<string>("string");
export const numberVariation = forceType<number>("number");
