/**
 * If making changes in here, please utilize the render-palettes script to
 * quickly review changes across all palettes.
 */
import {
  type Color,
  filterSaturate,
  formatHsl,
  interpolate,
  modeHsl,
  modeOklab,
  modeRgb,
  useMode,
} from "culori/fn";

// We use culori in tree-shaking mode (with the fn package). This means we need
// declare the color modes we want to use up front.
// Ref: https://culorijs.org/guides/tree-shaking/
const okLab = useMode(modeOklab);
useMode(modeHsl);
useMode(modeRgb);

// Helper type representing a colors object, with keys e.g. [red1, red2, etc.]
export type Colors<T extends string> = {
  [K in `${T}${number}`]: string;
};

/**
 * Maps a string array of palette colors to a Colors object, where each value is
 * an HSL color. Each color is mapped to a 1-indexed key with the provided
 * prefix.
 *
 * For example: paletteToColors("red", [color1, color2, ...])
 *   -> { red1: "#ff0000", red2: "#ff0101", ... }
 *
 * @param prefix Name to prefix colors with
 * @param palette Array of culori colors
 * @returns Object mapping color names to HSL values
 */
export const paletteToColors = <T extends string>(
  prefix: T,
  palette: Color[],
): Colors<T> => {
  return palette.reduce((acc, value, index) => {
    acc[`${prefix}${index + 1}`] = formatHsl(value);
    return acc;
  }, {} as Colors<T>);
};

/**
 * Generates a standard color palette based on a primary color and a background.
 *
 * This generates the palette programmatically for consistency and ease of use.
 * This approach is definitely subject to change, but it's a decent starting
 * point until we can spend more design effort here.
 *
 * 13 colors are generated in total:
 * - 11 colors for the main palette. The "primary" color appears as the 6th
 *   color in the palette, with lighter and darker shades before/after.
 * - 2 specialized colors for secondary and primary text. These are generated by
 *   darkening the primary color further, and desaturating the secondary text
 *   color.
 *
 * All color manipulation is done in the OKLAB color space for better saturation
 * and "true to eye" modification.
 * Ref: https://bottosson.github.io/posts/oklab/
 *
 * @param primaryHex The main color of the palette - notably, this is NOT the
 * same as the primary text color, which will be a darker version of this color.
 * This will appear at the 6th position in the palette.
 * @param backgroundHex The background color of the palette - this will appear
 * as the first color in the palette.
 * @returns An array of 13 culori colors. We retain the underlying culori color
 * to allow for further manipulation and maximum precision.
 */
export const makePalette = (
  primaryHex: string,
  backgroundHex: string,
): Color[] => {
  const palette = [];

  // We start with the lighter part of the palette (colors 1-6). This is a
  // gradient from the background color to the primary color.
  //
  // We generate the gradient in the OKLAB color space, which provides better
  // perceptual uniformity than RGB and LAB.
  //
  // We also push the midpoint slightly up to have a softer gradient in the
  // lighter colors. Without this, the light colors "jump" up too quickly. This
  // is the significance of the 0.6 value.

  const light = interpolate([backgroundHex, 0.6, primaryHex], "oklab");

  // Note that 1.0 is the "end" of the interpolation. Because we want the first
  // color to be 0.0 and the last color to be 1.0, we use zero-indexed values
  // and divide by one less than the number of steps (6).
  for (let i = 0; i < 6; i++) {
    palette.push(light(i / 5));
  }

  // We then generate the darker part of the palette (colors 7-13). All of these
  // are hand-picked manipulations of the primary color, again in the OKLAB
  // color space for better saturation.

  const primary = okLab(primaryHex);
  if (!primary) {
    throw new Error(`Invalid primary color: ${primaryHex}`);
  }

  // Helper function to lighten and saturate the primary color.
  // Note that just darkening the color can lead to a loss of saturation (a
  // "muddy" appearance), so it is also necessary to boost the saturation to
  // varying degrees.
  const primaryLS = (l: number = 1, s: number = 1) =>
    filterSaturate(s)({ ...primary, l: primary.l * l });

  // TODO: These values are hand-picked and may need adjustment.

  // 7-9 are a fairly even blend towards a rich saturated shade.
  palette.push(primaryLS(0.95, 1.15));
  palette.push(primaryLS(0.9, 1.25));
  palette.push(primaryLS(0.85, 1.35));
  // 10 is nominally the palette's "main" color, a rich saturated version of the
  // primary color.
  palette.push(primaryLS(0.75, 1.45));

  // 11 is a slightly darkened version of the main color.
  palette.push(primaryLS(0.68, 1.5));
  // 12 is the secondary text color - intentionally a lighter and desaturated
  // version of color 13. It's used e.g. for alt text and placeholders.
  palette.push(primaryLS(0.6, 0.5));
  // 13 is the primary text color. It's a much darker shade, close-ish to black.
  palette.push(primaryLS(0.4, 1.3));

  return palette;
};
