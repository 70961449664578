import type {
  LDContext,
  LDMultiKindContext,
  LDSingleKindContext,
  LDUser,
} from "@launchdarkly/js-client-sdk-common";
import { retry } from "ts-retry-promise";

/** Logger for use by LaunchDarkly clients. */
export const ldLogger = {
  // Disable debug and info logging, as they are excessively noisy
  debug: () => {},
  info: () => {},
  warn: console.warn,
  error: console.error,
};

export type TypeOfResult =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "object"
  | "function";

const isLDUser = (context: LDContext): context is LDUser =>
  !("kind" in context);
const isLDSingleKindContext = (
  context: LDContext,
): context is LDSingleKindContext =>
  "kind" in context && context.kind !== "multi";

/** Add some context to existing LDContext, upgrading existing context into an
 * LDMultiContext if needed. */
export const updateKind =
  (multiUpdate: Omit<LDMultiKindContext, "kind">) =>
  (prevContext?: LDContext): LDContext => {
    const base: LDMultiKindContext = { kind: "multi", ...multiUpdate };
    if (!prevContext) {
      return base;
    } else if (isLDUser(prevContext)) {
      return { user: prevContext, ...base };
    } else if (isLDSingleKindContext(prevContext)) {
      const { kind: prevKind, ...prevRest } = prevContext;
      return { [prevKind]: prevRest, ...base };
    } else {
      // LDMultiContext
      return { ...prevContext, ...base };
    }
  };

export interface FlagProviderProps {
  children?: React.ReactNode;
  sdkKey?: string;
}

/**
 * Retry identifying the user to LaunchDarkly, with a timeout and retries.
 * Intended for internal use by the native and web implementations.
 */
export const retryIdentify = (identify: () => Promise<void>) => {
  void (async () => {
    try {
      await retry(identify, {
        timeout: 30 * 1000,
        retries: 2,
      });
    } catch (error) {
      console.warn(`Failed to identify LaunchDarkly user: ${error}`);
    }
  })();
};
