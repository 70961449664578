import { type ReactNode } from "react";

import { TamaguiProvider as TamaguiProviderOG } from "@medbillai/ui";

import config from "../../tamagui.config";
import { useRootTheme } from "../theme/UniversalThemeProvider";

export const TamaguiProvider = ({ children }: { children: ReactNode }) => {
  const [rootTheme] = useRootTheme();

  return (
    <TamaguiProviderOG
      config={config}
      disableInjectCSS
      disableRootThemeClass
      defaultTheme={rootTheme}
    >
      {children}
    </TamaguiProviderOG>
  );
};
