import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import type { LDContext } from "@launchdarkly/js-client-sdk-common";
import * as Crypto from "expo-crypto";
import { Base64 } from "js-base64";
import type React from "react";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";

import { gql } from "@medbillai/graphql-types";

import { analyticsService } from "../analytics";
import { errorHandling } from "../apollo/utils";
import { launchDarklyIdentify } from "../flag";
import { pushNotificationsService } from "../push-notifications";
import { sentryService } from "../sentry";
import { singularService } from "../singular";
import { AuthContext, type AuthContextInterface } from "./authContext";
import {
  type ProfileData,
  type User,
  initialAuthState,
  usePersistedAuthReducer,
} from "./authState";
import { useIdentityCheck } from "./identity";

export interface AuthProviderProps {
  children?: React.ReactNode;
  authRedirectUri: () => string;
}

const normalizeError =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    // Special handling for GQL errors which can be an arbitrary object.
    if (error && typeof error === "object" && "message" in error) {
      return new Error(error.message as string);
    }
    if (typeof error === "string") {
      return new Error(error);
    }
    return new Error(fallbackMessage);
  };

const profileError = normalizeError("Profile load failed");
const magicLinkError = normalizeError("Registration failed");
const logoutError = normalizeError("Logout failed");
const shadowLoginError = normalizeError("Shadow login failed");

const sendMagicLinkMutation = gql(/* GraphQL */ `
  mutation SendMagicLink($input: SendMagicLinkInput!) {
    sendMagicLink(input: $input) {
      ... on SendMagicLinkResponse {
        user {
          id
          isAdmin
          isShadow
          adminUserId
        }
        created
      }
      ... on FormError {
        message
        fields {
          path
          message
        }
      }
    }
  }
`);

const authenticateMagicLinkMutation = gql(/* GraphQL */ `
  mutation AuthenticateMagicLink($input: AuthenticateMagicLinkInput!) {
    authenticateMagicLink(input: $input) {
      user {
        id
        isAdmin
        isShadow
        adminUserId
      }
      success
      errorType
    }
  }
`);

const logoutMutation = gql(/* GraphQL */ `
  mutation Logout {
    logout
  }
`);

const shadowLoginMutation = gql(/* GraphQL */ `
  mutation ShadowLogin($input: ShadowLoginInput!) {
    shadowLogin(input: $input) {
      ... on AuthUser {
        id
        isAdmin
        isShadow
        adminUserId
      }
      ... on FormError {
        fields {
          path
          message
        }
      }
    }
  }
`);

const profileQuery = gql(/* GraphQL */ `
  query Profile {
    me {
      id
      isAdmin
      primaryEmail
      profile {
        name
      }
    }
  }
`);

export const AuthProvider = (props: AuthProviderProps) => {
  // Mutation functions for auth.
  const [doSendMagicLink] = useMutation(sendMagicLinkMutation, {
    context: errorHandling("caller"),
    ignoreResults: true,
  });
  const [doAuthenticateMagicLink] = useMutation(authenticateMagicLinkMutation, {
    context: errorHandling("caller"),
    ignoreResults: true,
  });
  const [doLogout] = useMutation(logoutMutation, {
    context: errorHandling("no-log"),
    ignoreResults: true,
  });
  const [
    doShadowLogin,
    { called: shadowLoginCalled, reset: shadowLoginReset },
  ] = useMutation(shadowLoginMutation);

  const apolloClient = useApolloClient();

  // Primary stateful store for the authentication state. This is persisted to localStorage so that
  // we can assume logged-in status across page loads.
  const {
    state,
    dispatch,
    initialize: initializeReducer,
  } = usePersistedAuthReducer(initialAuthState);

  const didInitializeReducer = useRef(false);

  // Slightly hacky workaround to support loading state from localStorage - we need to wait for
  // a render via useEffect before accessing localStorage, due to the way that NextJS operates
  useEffect(() => {
    if (didInitializeReducer.current) {
      return;
    }
    didInitializeReducer.current = true;
    initializeReducer();
  }, [didInitializeReducer.current]);

  const logout = useCallback(async () => {
    dispatch({ type: "LOGOUT" });
    try {
      pushNotificationsService.deregisterDevice();
      try {
        // This is only to forcefully revoke the session if one is still active. We want to ignore
        // any errors and continue with the client logout.
        await doLogout({
          errorPolicy: "ignore",
        });
      } catch (err) {
        // Do nothing
      }
      // Clear any objects in the Apollo cache, since they are associated with the logged-in user.
      await apolloClient.clearStore();
      analyticsService.resetUser();
      sentryService.clearUser();
      singularService.resetUserId();
    } catch (error) {
      dispatch({ type: "ERROR", error: logoutError(error) });
      throw error;
    }
  }, [doLogout]);

  // This function is used on application startup, and after shadow login.
  const [queryProfile] = useLazyQuery(profileQuery, {
    // Need to avoid the cache for shadow login.
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    context: errorHandling("no-log"),
  });
  const fetchProfile = useCallback(async (): Promise<
    ProfileData | undefined
  > => {
    let profile: ProfileData | undefined;
    try {
      const profileResult = await queryProfile();
      // Check for expiration of the user's session - in that case, just return undefined.
      // The calling code will see this and log the user out.
      if (
        profileResult.error?.graphQLErrors?.some(
          gqlErr =>
            gqlErr.extensions?.code === "UNAUTHENTICATED" ||
            gqlErr.message.includes("session token"),
        )
      ) {
        return;
      }

      if (profileResult.error?.graphQLErrors?.[0]) {
        throw profileError(profileResult.error.graphQLErrors[0]);
      }
      if (profileResult.error?.networkError) {
        throw profileError(profileResult.error.networkError);
      }

      const profileData = profileResult.data?.me;
      if (!profileData) {
        throw profileError("Missing profile data");
      }

      profile = {
        name: profileData.profile?.name ?? undefined,
        email: profileData.primaryEmail ?? "unknown@unknown.com",
        isAdmin: profileData.isAdmin,
      };
    } catch (error) {
      throw profileError(error);
    }
    return profile;
  }, [queryProfile, logout]);

  // Identify the user to LaunchDarkly whenever it changes. Fields are mostly
  // arbitrary, but we try to match those set in the API service.
  // https://docs.launchdarkly.com/sdk/features/identify#react-native
  const userContext: LDContext = useMemo(() => {
    if (!state.user?.id || !state.user?.profile?.email) {
      return {
        kind: "user",
        key: "",
        anonymous: true,
      };
    }
    return {
      kind: "user",
      key: state.user.id,
      name: state.user.profile.name ?? state.user.profile.email,
      email: state.user.profile.email,
      isAdmin: state.user.isAdmin ?? false,
    };
  }, [state.user?.id, state.user?.isAdmin, state.user?.profile?.email]);
  useEffect(() => launchDarklyIdentify(userContext), [userContext]);

  // This function is responsible for loading the profile data at application startup. It
  // serves double-duty as a validation of the session token when the application first
  // loads. If we fail to retrieve the profile, we assume the token is invalid and log the
  // user out.
  // The function also causes the profile to be fetched automatically if a user logs in.
  useEffect(() => {
    if (!state.isHydrated || state.error) {
      return;
    }
    if (!state.isAuthenticated) {
      // If we just finished hydrating the state, and the user is not logged in, set the
      // ready flag to true.
      if (!state.isReady) {
        dispatch({ type: "AUTH_READY" });
      }
      return;
    }
    if (state.isReady && state?.user?.profile) {
      // If we already have a profile after initial startup, do nothing.
      return;
    }
    // Otherwise, try to fetch profile data.
    void (async () => {
      try {
        const profile = await fetchProfile();

        if (!profile) {
          // Expired session - log the user out.
          console.debug(
            "Failed to retrieve user profile at startup due to an expired session. Logging out.",
          );
          await logout();
          return;
        }

        dispatch({ type: "PROFILE_LOADED", profile });

        if (state?.user?.id) {
          if (!state.user.isShadow) {
            // We only register the device for non-shadow users, as well as send
            // analytics so we do not polute the data.
            pushNotificationsService.registerDevice(
              profile.email,
              state.user.id,
            );
            analyticsService.identifyUser(state?.user?.id, {
              email: profile.email,
              name: profile.name,
            });
            singularService.setUserId(state?.user.id);
          }
          sentryService.setUser({
            id: state.user.id,
            email: profile.email,
            shadowUser: state.user.isShadow,
          });
        }

        // Specify "ready" no matter what - even if we had to log the user out.
        if (!state.isReady) {
          dispatch({ type: "AUTH_READY" });
        }
      } catch (error) {
        // Don't bother logging network errors.
        if (
          !(
            error instanceof TypeError &&
            error.message === "Network request failed"
          )
        ) {
          console.error(`Failed to load profile: ${error}`);
        }
        // Also sets isReady to true.
        dispatch({ type: "ERROR", error: profileError(error) });
      }
    })();
  }, [
    state.isHydrated,
    state.isReady,
    state.isAuthenticated,
    state.error,
    fetchProfile,
    logout,
  ]);

  // Wrap all functions in useCallback to prevent state churn and unnecessary re-renders
  const sendMagicLink = useCallback(
    async (email: string) => {
      // Need to generate a new code verifier and challenge.
      // TODO: This is kind of a mess - is there a better way? RN doesn't have great support
      // for Buffer out of the box, and the polyfills don't implement base64url. Have had to
      // use js-base64, which is not ideal.
      const codeVerifierBytes = Crypto.getRandomBytes(44);
      const codeVerifier = Base64.fromUint8Array(codeVerifierBytes, true);
      dispatch({ type: "CODE_VERIFIER", codeVerifier });

      const codeChallenge = Base64.fromUint8Array(
        // TODO: This is even worse...
        new Uint8Array(
          await Crypto.digest(
            Crypto.CryptoDigestAlgorithm.SHA256,
            Uint8Array.from(
              codeVerifier.split("").map(letter => letter.charCodeAt(0)),
            ),
          ),
        ),
        true,
      );

      // Will throw any errors up to the caller.
      const result = await doSendMagicLink({
        variables: {
          input: {
            email,
            clientRedirectUri: props.authRedirectUri(),
            codeChallenge,
          },
        },
      });

      const data = result.data?.sendMagicLink;
      if (!data) {
        throw magicLinkError("Missing data on response");
      }
      if (data.__typename === "FormError") {
        return data;
      }
      if (data.__typename === "SendMagicLinkResponse") {
        return data;
      }
      throw magicLinkError("Unexpected response type");
    },
    [dispatch, doSendMagicLink, state.codeVerifier],
  );

  const authenticateMagicLink = useCallback(
    async (token: string) => {
      dispatch({ type: "LOGIN_REQUESTED" });

      // Will throw any GQL/network errors up to the caller.
      const result = await doAuthenticateMagicLink({
        variables: {
          input: {
            token,
            codeVerifier: state.codeVerifier,
          },
        },
      });

      const data = result.data?.authenticateMagicLink;
      if (!data) {
        throw magicLinkError("Missing data on response");
      }

      if (data.__typename === "AuthenticateMagicLinkResult") {
        if (data.success) {
          // Log the user in on success. Otherwise the caller will handle the error on
          // the data object.
          if (!data.user) {
            throw magicLinkError("Missing user on response");
          }

          // This will also clear codeVerifier from state.
          const user = {
            ...data.user,
            // Can come back as `null` from GQL, so we convert to `undefined`.
            adminUserId: data.user?.adminUserId ?? undefined,
          };
          dispatch({
            type: "LOGIN_SUCCEEDED",
            user,
          });
        }
        return data;
      }
      throw magicLinkError("Unexpected response type");
    },
    [dispatch, doAuthenticateMagicLink, state.codeVerifier],
  );

  const disableScreenLock = useCallback(
    () =>
      dispatch({ type: "SCREEN_LOCK_OVERRIDE", isScreenLockSuppressed: true }),
    [dispatch],
  );
  const enableScreenLock = useCallback(
    () =>
      dispatch({ type: "SCREEN_LOCK_OVERRIDE", isScreenLockSuppressed: false }),
    [dispatch],
  );

  const { identityCheck } = useIdentityCheck(
    logout,
    dispatch,
    state.isReady,
    state.isHydrated,
    state.isAuthenticated,
    state.lastActiveTime,
    state.hasRegisteredBiometrics,
    state.isIdentityChecked,
    state.identityCheckError,
  );

  // Lets the current administrator user switch their session to a shadow session, where
  // they impersonate another user.
  const shadowLogin = useCallback(
    async (email: string) => {
      if (shadowLoginCalled) {
        // Another request is in-flight.
        return;
      }

      // Will throw any GQL/network errors up to the caller.
      const result = await doShadowLogin({
        variables: {
          input: {
            email,
          },
        },
      });

      // Reset the "called" flag so that we can try again.
      shadowLoginReset();

      const data = result.data?.shadowLogin;
      if (!data) {
        throw shadowLoginError("Missing data on response");
      }
      if (data.__typename === "AuthUser") {
        // Success!
        // The user data on the response only includes non-profile fields, so we need to
        // explicitly re-fetch profile data with the new session token.
        const profile = await fetchProfile();
        const user = {
          ...data,
          // Can come back as `null` from GQL, so we convert to `undefined`.
          adminUserId: data.adminUserId ?? undefined,
          profile,
        };
        dispatch({ type: "SHADOW_LOGIN", user });
        return data;
      }
      if (data.__typename === "FormError") {
        return data;
      }
      throw shadowLoginError("Unexpected response type");
    },
    [
      dispatch,
      doShadowLogin,
      shadowLoginCalled,
      shadowLoginReset,
      fetchProfile,
    ],
  );

  // Memoize to prevent unnecessary re-renders
  const contextValue = useMemo<AuthContextInterface>(
    () => ({
      ...state,
      sendMagicLink,
      authenticateMagicLink,
      logout,
      shadowLogin,
      identityCheck,
      enableScreenLock,
      disableScreenLock,
    }),
    [
      state,
      sendMagicLink,
      authenticateMagicLink,
      logout,
      shadowLogin,
      identityCheck,
      enableScreenLock,
      disableScreenLock,
    ],
  );
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

// useAuth provides a React hook to lift the AuthContext and access state and methods.
export const useAuth = <TUser extends User = User>(
  context = AuthContext,
): AuthContextInterface<TUser> =>
  useContext(context) as AuthContextInterface<TUser>;
