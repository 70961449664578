import { createFont } from "tamagui";

// Currently, we are using a shared configuration across all platforms. In the
// future, we may want to break out a `fonts.native.ts` if we need to
// differentiate typography between platforms.

// We define explicit sizes to match the Granted typography system.
// https://www.figma.com/design/wZwnC3WQuCYMErlO0vbnJ3/Granted-Design-System?node-id=1-12407
//
// The standard typography elements in the design system are defined as follows:
// - Headline 1     -> heading $11
// - Subheadline 1  -> body $10
// - Subheadline 2  -> body $9
// - Subheadline 3  -> body $8
// - Subheadline 4  -> body $7
// - Subheadline 5  -> body $6
// - Title          -> body $5
// - Body           -> body $4
// - Body 2         -> body $3
// - Small          -> body $2
//
// Note:
// - Subheadlines utilize the body font, but we define special elements that
//   apply the correct font weight and line height.
// - Many other size tokens are not defined by the design system, but we want to
//   provide some sane defaults for them anyway, in case they are needed for a
//   specific scenario.
//
// We have defined most of the available weights for both fonts, even though not
// all of them are currently used. This allows flexibility for design scenarios
// that may require specific font weights.

// Note that sizes must be defined in order from smallest to largest.
// Non-standard sizes have not been vetted by design, and may be subject to
// change.
//
// Default font sizes provided by Tamagui, for reference:
// 1: 11,
// 2: 12,
// 3: 13,
// 4: 14,  // Paragraph
// 5: 16,  // H6
// 6: 18,  // H5
// 7: 20,  // H4
// 8: 23,  // H3
// 9: 30,  // H2
// 10: 46, // H1
// 11: 55,
// 12: 62,
// 13: 72,
// 14: 92,
// 15: 114,
// 16: 134,
export const fontSize = {
  1: 11,
  // Body Small
  2: 12,
  // Body 2
  3: 14,
  // Body
  4: 16,
  // Title
  5: 18,
  // Subheadline 5
  6: 20,
  // Subheadline 4
  7: 24,
  // Subheadline 3
  8: 28,
  //  Subheadline 2
  9: 32,
  //  Subheadline 1
  10: 36,
  // Headline 1
  11: 40,
  12: 48,
  13: 60,
  14: 72,
};

// Line height is pulled from the design system.
const headingLineHeight = 1.1;
export const headingFont = createFont({
  family: "NCKobyla",
  size: {
    ...fontSize,
    // Default is Headline 2
    true: 36,
  },
  weight: {
    // Standard heading typography is Light.
    true: "300",
  },
  lineHeight: Object.fromEntries(
    Object.entries(fontSize).map(([k, v]) => [k, v * headingLineHeight]),
  ),
  // Face has no effect on web - for web mapping, use fonts.css.
  face: {
    normal: {
      normal: "NCKobyla-Light",
    },
    bold: {
      normal: "NCKobyla-Regular",
    },
    // Only a subset of weights available, and no italic faces.
    200: {
      normal: "NCKobyla-Ultra-Light",
    },
    300: {
      normal: "NCKobyla-Light",
    },
    400: {
      normal: "NCKobyla-Regular",
    },
    500: {
      normal: "NCKobyla-Medium",
    },
    600: {
      normal: "NCKobyla-Semibold",
    },
    700: {
      normal: "NCKobyla-Bold",
    },
  },
});

// Subheadline font is the same as body font, but has a different line height
// and font weights. However, Tamagui does not like multiple fonts with the same
// font family, so we have to modify the font on custom Subheadline components -
// see Typography.tsx for details.
export const subheadlineLineHeight = 1.1;

const bodyLineHeight = 1.45;
export const bodyFont = createFont({
  family: "KHTeka",
  size: {
    ...fontSize,
    // Default is Body
    true: 16,
  },
  weight: {
    // Standard body typography is Regular.
    1: "400",
    true: "400",
  },
  lineHeight: Object.fromEntries(
    Object.entries(fontSize).map(([k, v]) => [k, v * bodyLineHeight]),
  ),
  face: {
    normal: {
      normal: "KHTeka-Regular",
      italic: "KHTeka-RegularItalic",
    },
    bold: {
      normal: "KHTeka-Medium",
      italic: "KHTeka-MediumItalic",
    },
    // Only a subset of weights available.
    300: {
      normal: "KHTeka-Light",
      italic: "KHTeka-LightItalic",
    },
    400: {
      normal: "KHTeka-Regular",
      italic: "KHTeka-RegularItalic",
    },
    500: {
      normal: "KHTeka-Medium",
      italic: "KHTeka-MediumItalic",
    },
    // Fake an in-between weight.
    600: {
      normal: "KHTeka-Bold",
      italic: "KHTeka-BoldItalic",
    },
    700: {
      normal: "KHTeka-Bold",
      italic: "KHTeka-BoldItalic",
    },
    900: {
      normal: "KHTeka-Black",
      italic: "KHTeka-BlackItalic",
    },
  },
});
