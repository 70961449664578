import { type ReactNode } from "react";

// is handled on _app.tsx
export const UniversalThemeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <>{children}</>;
};

export { useRootTheme, useThemeSetting } from "@tamagui/next-theme";
