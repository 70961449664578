import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { Kind, OperationTypeNode } from "graphql";
import { createClient } from "graphql-ws";
import { useMemo } from "react";

import { mergeEOBS, mergeIssues, readIssues } from "./apolloHelpers";
import { errorLink } from "./error";
import { retryLink } from "./retry";

export const createApolloClient = (
  apolloPath: string,
  apolloWsPath: string,
  rethrowError?: (error: Error) => void,
  onAuthError?: () => Promise<void>,
) => {
  const httpLink = new HttpLink({
    uri: apolloPath,
    credentials: "include",
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: () => {
        if (!window) {
          // In theory this should not happen
          throw new Error("window is not defined");
        }
        const { protocol, host } = window.location;
        return (
          (protocol === "http:" ? "ws" : "wss") + `://${host}${apolloWsPath}`
        );
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION
      );
    },
    wsLink,
    httpLink,
  );

  const errLink = useMemo(
    () => errorLink(rethrowError, onAuthError),
    [rethrowError, onAuthError],
  );

  return new ApolloClient({
    link: from([errLink, retryLink, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            queryIssuesV2: {
              keyArgs: [],
              merge: mergeIssues,
              read: readIssues,
            },
            queryExplanationOfBenefitsV2: {
              keyArgs: ["inNetwork", "outOfNetwork", "denied"],
              merge: mergeEOBS,
            },
          },
        },
      },
    }),
  });
};
