"use client";

import { useEffect } from "react";

import { initHoneycomb } from "@medbillai/app/src/lib/honeycomb";

// Although "useEffect" will only run the hook once per mount, hot reloading can
// cause us to mount multiple times, so track initialization separately to avoid
// error noise.
let initialized = false;

/**
 * A hook that initializes the Honeycomb observability library.
 * Only intended for the nextjs app, not the react native app.
 */
export function useObservability() {
  useEffect(() => {
    if (initialized) {
      return;
    }
    initialized = true;
    initHoneycomb({
      apiKey: process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY!,
      serviceName:
        process.env.NEXT_PUBLIC_HONEYCOMB_SERVICE_NAME || "granted-next",
      debug: process.env.NODE_ENV !== "production",
    });
  }, []);
}
