import {
  cream,
  creamHex,
  grantedColors,
  gray,
  green,
  lightBlue,
  lightBlueHex,
  limeGreen,
  limeGreenHex,
  navy,
  navyHex,
  orange,
  red,
  strokeSecondaryHex,
  strokeTertiaryHex,
  textTertiaryHex,
} from "./colors-granted";

export const colorTokens = {
  light: {
    granted: grantedColors,
    cream,
    gray,
    green,
    lightBlue,
    limeGreen,
    navy,
    orange,
    red,
  },
};

export const lightColors = {
  ...colorTokens.light.cream,
  ...colorTokens.light.gray,
  ...colorTokens.light.green,
  ...colorTokens.light.lightBlue,
  ...colorTokens.light.limeGreen,
  ...colorTokens.light.navy,
  ...colorTokens.light.orange,
  ...colorTokens.light.red,
};

// These are turned into fixed named color tokens which can be used under any
// theme (e.g. $navy).
export const grantedBrandColors = {
  navy: navyHex,
  lightBlue: lightBlueHex,
  cream: creamHex,
  limeGreen: limeGreenHex,
  // Some colors that didn't make it into the primary palette. Others are
  // available by name under the Granted theme - e.g. $backgroundSecondary,
  // $strokePrimary, etc.
  strokeSecondary: strokeSecondaryHex,
  strokeTertiary: strokeTertiaryHex,
  textTertiary: textTertiaryHex,
};

export const color = {
  // Granted brand colors
  ...grantedBrandColors,
  // White/black
  white0: "rgba(255,255,255,0)",
  white075: "rgba(255,255,255,0.75)",
  white05: "rgba(255,255,255,0.5)",
  white025: "rgba(255,255,255,0.25)",
  black0: "rgba(10,10,10,0)",
  black075: "rgba(10,10,10,0.75)",
  black05: "rgba(10,10,10,0.5)",
  black025: "rgba(10,10,10,0.25)",
  white1: "#fff",
  white2: "#f8f8f8",
  white3: "hsl(0, 0%, 96.3%)",
  white4: "hsl(0, 0%, 94.1%)",
  white5: "hsl(0, 0%, 92.0%)",
  white6: "hsl(0, 0%, 90.0%)",
  white7: "hsl(0, 0%, 88.5%)",
  white8: "hsl(0, 0%, 81.0%)",
  white9: "hsl(0, 0%, 56.1%)",
  white10: "hsl(0, 0%, 50.3%)",
  white11: "hsl(0, 0%, 42.5%)",
  white12: "hsl(0, 0%, 12.0%)",
  white13: "hsl(0, 0%, 5.0%)",
  black1: "#050505",
  black2: "#151515",
  black3: "#191919",
  black4: "#232323",
  black5: "#282828",
  black6: "#323232",
  black7: "#424242",
  black8: "#494949",
  black9: "#545454",
  black10: "#626262",
  black11: "#a5a5a5",
  black12: "#ccc",
  black13: "#fff",
  // Light colors
  ...postfixObjKeys(lightColors, "Light"),
};

export function postfixObjKeys<
  A extends { [key: string]: string },
  B extends string,
>(
  obj: A,
  postfix: B,
): {
  [Key in `${keyof A extends string ? keyof A : never}${B}`]: string;
} {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [`${k}${postfix}`, v]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
}
