import {
  type SingularConfigProps,
  type SingularEventArgs,
  type SingularEventName,
} from "./types";

class SingularService {
  private initialized = false;
  private userId: string | undefined = undefined;
  private skanConversionValue: number | undefined = undefined;
  private singularTrackingEnabled: boolean | undefined = true;

  initialize(__config: SingularConfigProps): void {
    return;
  }

  setUserId(__userId: string) {
    return;
  }

  resetUserId() {
    return;
  }

  event(__eventName: SingularEventName, __args?: SingularEventArgs) {
    return;
  }
}

const singularService = new SingularService();
export { singularService };
