import type { ApiOptions } from "@rudderstack/analytics-js/*";

import { type ExitError } from "@medbillai/ui";

export type AnalyticsConfig = {
  writeKey: string;
  dataPlaneUrl: string;
  // when running in debug mode, events will be logged to the console
  debug?: boolean;
};

export const defaultConfig: ApiOptions = {
  integrations: {
    All: true,
    "Facebook Conversions": false,
    "Google Ads Enhanced conversions Mobile App": false,
  },
};

export const trackingConfig: ApiOptions = {
  integrations: {
    All: true,
    "Facebook Conversions": true,
    // c is lower case to match "Display Name"
    "Google Ads Enhanced conversions Mobile App": true,
  },
};

type Endpoint = "FLEXPA" | "WEB_AGENT";
type InsuranceLinkPage =
  | "RELINK"
  | "LOGIN"
  | "MFA_CODE"
  | "SECURITY_QUESTION"
  | "SELECT_MFA"
  | "MANAGED_MFA_APPROVAL"
  | "SUCCESS"
  | "ERROR";

export type EventTypes = {
  // Application lifecycle
  ClientVersionMismatch: {
    clientBinary: "OLD" | "NEW";
    clientVersion: string;
    apiVersion: string;
  };
  StartedEASUpdate: {
    clientVersion: string;
    easUpdateId: string;
  };
  EASUpdateMismatch: {
    clientVersion: string;
    supportedUpdateId: string | null;
    currentUpdateId: string | null;
    availableUpdateId: string | null;
  };
  SubmitLogin: Record<string, never>;
  FaceIdLogoutButton: Record<string, never>;
  FaceIdVerifyButton: Record<string, never>;
  EmailVerificationLinkError: {
    type: "INVALID_PKCE_CODE_CHALLENGE" | "UNKNOWN_ERROR";
  };
  ClickedTab: { tabName: string };
  ClickedSettings: Record<string, never>;
  OnboardingBackStep: { initialRoute: string };

  // Notifications
  ClickedEnableNotifications: Record<string, never>;
  ClickedDisableNotifications: Record<string, never>;
  ClickedSkipNotifications: Record<string, never>;
  SubmitHipaaRelease: Record<string, never>;

  // Chat
  CreateIssue: { title: string };
  SentMessage: { issueId: string };
  OpenedIssue: { id: string };
  ClickedUpload: { type: "FILE" | "GALLERY" | "CAMERA" };
  ClickedChatEvent: {
    type:
      | "DOCUMENT_UPLOADED"
      | "HIPAA_RELEASE_REQUESTED"
      | "TEMPLATE_RELEASE_REQUESTED";
  };
  ClickedNewChatEventbutton: Record<string, never>;
  HipaaReleaseSigned: { templateId: string; organizationSlug?: string };

  // Insurance Linking Steps Web Agent
  WebAgentCredentials: Record<string, never>;
  WebAgentMfaSelection: { preferredMethod: string | undefined };
  WebAgentSecurityQuestion: Record<string, never>;
  WebAgentMfaCode: Record<string, never>;
  WebAgentResendMfaCode: Record<string, never>;
  WebAgentManagedMfaApproval: { approved: boolean };

  // Flexpa Linking Steps
  FlexpaCheckSupport: { planSupported: boolean };
  FlexpaUnsupportedPlan: { insuranceName: string | undefined };

  // Insurance Linking General
  FinishedInsuranceLinking:
    | { linkType: Endpoint; status: "ERROR"; errorCode: ExitError }
    | { linkType: Endpoint; status: "SUCCESS" }
    | { linkType: Endpoint; status: "EXIT" };
  InsuranceLinkAlternatePlan: Record<string, never>;
  InsuranceLinkErrorStep: { step: InsuranceLinkPage };
  InsuranceLinkBackStep: { initialRoute: string };
  ClickedConnectInsurance: {
    linkType: Endpoint;
    insuranceName: string | undefined | null;
  };
  ClickedAddInsurance: { linkType: Endpoint };
  StartInsuranceLinkOnboarding: Record<string, never>;
  SkipInsuranceLinkOnboarding: Record<string, never>;
  InsuranceLinkOnboardingBackStep: { initialRoute: string };
  SelectedInsuranceType: Record<string, never>;

  // User created tracking application installs/signups
  UserCreated: { email: string };

  // Bills
  OpenedBill: { id: string };

  // Feedback
  FeedbackProvided: { rating: number };
  FeedbackDeepLink:
    | { result: "SUCCESS" }
    | { result: "ERROR"; error: "CASE_OPEN" };
};

export type UserTraits = {
  name?: string;
  email?: string;
};

export type ScreenViewProperties = Record<string, string | string[]>;

// Define events to be sent to third-party tracking software listed in the
// trackingConfig. Custom events can use specific configurations if necessary.
export const eventTrackingConfig: {
  [E in keyof EventTypes]?: ApiOptions;
} = {
  UserCreated: trackingConfig, // Mapped to Signed Up properties: {email} preserve in transformation
  CreateIssue: trackingConfig, // Mapped to View Content (properties: { title } is purged in transformation
  // Add more events with custom configs as needed
};

export interface AnalyticsServiceInterface {
  initialize(config: AnalyticsConfig): Promise<void>;
  trackEvent<E extends keyof EventTypes>(
    eventName: E,
    attributes: EventTypes[E],
  ): void;
  identifyUser(userId: string, traits: UserTraits): void;
  resetUser(): void;
  trackScreenView(screenName: string, properties: ScreenViewProperties): void;
}
