import { useRouter } from "solito/router";

import { Button, ParagraphSmall, Text, XStack } from "@medbillai/ui";

import { loginUrl } from "@medbillai/utils/urls";

import { useAuth } from "../lib/auth";

export const ShadowLoginBanner = () => {
  const { isReady, user } = useAuth();
  const { replace } = useRouter();

  if (!isReady || !user?.isShadow) {
    return null;
  }

  return (
    <XStack gap="$3" ai="center" bc="$red7" p="$3" fg={0}>
      <ParagraphSmall fg={1} fs={1}>
        WARNING: You are currently using Shadow Login mode as{" "}
        <Text bold>{user.profile?.email ?? "unknown@unknown.com"}</Text>.
      </ParagraphSmall>
      <Button
        size="$1"
        onPress={() => replace(loginUrl({ forceLogout: true }))}
        fs={0}
      >
        Logout
      </Button>
    </XStack>
  );
};
