import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import {
  type Instrumentation,
  type InstrumentationConfig,
} from "@opentelemetry/instrumentation";

export const configDefaults = {
  ignoreNetworkEvents: true,
  propagateTraceHeaderCorsUrls: [
    /^https?:\/\/([a-zA-Z0-9-]+\.)?medbill\.ai\/api\/.*/,
    /^https?:\/\/([a-zA-Z0-9-]+\.)?grantedhealth\.com\/api\/.*/,
    /^https?:\/\/localhost:[0-9]+\/api\/.*/,
  ],
};

export function getInstrumentations(): Instrumentation<InstrumentationConfig>[] {
  return getWebAutoInstrumentations({
    "@opentelemetry/instrumentation-xml-http-request": configDefaults,
    "@opentelemetry/instrumentation-fetch": configDefaults,
    "@opentelemetry/instrumentation-document-load": configDefaults,
    "@opentelemetry/instrumentation-user-interaction": { enabled: false },
  });
}
