/**
 * Setup for Honeycomb observability on the web.
 * Constructs an OpenTelemetry SDK with web-specific configurations.
 */
import { HoneycombWebSDK } from "@honeycombio/opentelemetry-web";
import { context } from "@opentelemetry/api";
import { ZoneContextManager } from "@opentelemetry/context-zone";

import { getInstrumentations } from "./telemetryConfig";

export type InitHoneycombParams = {
  apiKey: string;
  serviceName: string;
  debug: boolean;
};

export function initHoneycomb({
  apiKey,
  serviceName,
  debug,
}: InitHoneycombParams) {
  try {
    /**
     * # Some Context about Context Managers
     *
     * Context is complicated. You can't just stash something in a global, run a
     * function, and pop it back, because so much of JS involves async execution.
     *
     * JS unfortunately doesn't have a good answer. Node provides an `async
     * hooks` API that OTel uses server-side. The closest approximation in the
     * browser is `zone.js`, a library from the AngularJS people that does its
     * job by patching builtins like `Promise.then`.
     *
     * While zone.js can't directly patch `async`/`await` syntax (standardized
     * in ES2017), we can make it work by ensuring the code is down-leveled to
     * ES5. To make SWC do this, we tossed good old "ie 11" in our next
     * package.json browserslist config.
     *
     * P.S.: React is a whole other layer of async scope - but instead of
     * grappling with that, we passed the context manually as far as we could,
     * switching back to the context manager at the "leaves" (e.g. calling
     * mutations) so that the built-in instrumentations know what's up.
     *
     * Cleaning this up is on the backlog: ENG-1775
     **/
    const contextManager = new ZoneContextManager();
    const sdk = new HoneycombWebSDK({
      apiKey,
      serviceName,
      debug,
      webVitalsInstrumentationConfig: { enabled: false }, // Disables UX metrics (LCP, CLS, INP)
      instrumentations: getInstrumentations(),
      contextManager,
      localVisualizations: debug, // log urls for created traces to the console
    });
    context.setGlobalContextManager(contextManager);
    sdk.start();
  } catch (e) {
    console.error("Failed to initialize Honeycomb:", e);
  }
}
