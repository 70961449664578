import { RudderAnalytics } from "@rudderstack/analytics-js";

import {
  type AnalyticsConfig,
  type AnalyticsServiceInterface,
  type EventTypes,
  type ScreenViewProperties,
  type UserTraits,
  defaultConfig,
  eventTrackingConfig,
} from "./types";

class AnalyticsService implements AnalyticsServiceInterface {
  private runInDebugMode = false;
  private initialized = false;
  private rudderAnalytics?: RudderAnalytics;

  private debugLog(message: string, details: Record<string, unknown>) {
    if (this.runInDebugMode) {
      console.debug(`[Analytics]: ${message}`, JSON.stringify(details));
    }
  }

  async initialize(config: AnalyticsConfig): Promise<void> {
    this.rudderAnalytics = new RudderAnalytics();
    this.runInDebugMode = config.debug || false;
    this.rudderAnalytics.load(config.writeKey, config.dataPlaneUrl);
    this.rudderAnalytics.ready(() => {
      this.initialized = true;
    });

    return Promise.resolve();
  }

  private checkInitialized() {
    if (!this.initialized && !this.rudderAnalytics) {
      this.debugLog(`Dropping event - not initialized yet`, {});
      console.warn("Dropping event - not initialized yet");
      return false;
    }
    return true;
  }

  trackEvent<E extends keyof EventTypes>(
    eventName: E,
    properties: EventTypes[E],
  ): void {
    this.debugLog("trackEvent", { eventName, properties });
    if (!this.checkInitialized()) {
      console.warn(
        `Event Dropped Track Event- Name: ${eventName} ; Properties:`,
        properties,
      );
      return;
    }
    const config = eventTrackingConfig[eventName] || defaultConfig;
    this.rudderAnalytics?.track(eventName, properties, config);
  }

  identifyUser(userId: string, traits: UserTraits): void {
    if (!traits.email) {
      console.warn("Identifying user without email", { userId, traits });
    }
    this.debugLog("identifyUser", { userId, traits });
    if (!this.checkInitialized()) {
      console.warn(
        `Event Dropped Identify User - UserId: ${userId} ; Properties:`,
        traits,
      );
      return;
    }
    this.rudderAnalytics?.identify(userId, traits);
  }

  resetUser(): void {
    this.debugLog("Reset user for analytics", {});
    if (!this.checkInitialized()) {
      console.warn(`Event Dropped Reset User `);
      return;
    }
    this.rudderAnalytics?.reset();
  }

  trackScreenView(screenName: string, properties: ScreenViewProperties): void {
    this.debugLog("trackScreenView", { screenName, properties });
    if (!this.checkInitialized()) {
      console.warn(
        `Event Dropped Track Screen View- trackScreenView: ${screenName} ; Properties:`,
        properties,
      );
      return;
    }
    this.rudderAnalytics?.page(screenName, properties, defaultConfig);
  }
}

const analyticsService = new AnalyticsService();
export { analyticsService };
