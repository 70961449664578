import type React from "react";

import { PortalProvider } from "@medbillai/ui";

import { ApolloProvider, type ApolloProviderProps } from "./apollo";
import { UploadDocumentProvider } from "./documents";
import { NotificationProvider } from "./notifications";
import { SafeAreaProvider } from "./safe-area";
import { TamaguiProvider } from "./tamagui";
import { UniversalThemeProvider } from "./theme";
import { ToastProvider } from "./toast";

export type ProviderProps = ApolloProviderProps;

export function Provider({
  apolloPath,
  apolloWsPath,
  rethrowError,
  onAuthError,
  children,
}: ProviderProps) {
  return (
    <ApolloProvider
      apolloPath={apolloPath}
      apolloWsPath={apolloWsPath}
      rethrowError={rethrowError}
      onAuthError={onAuthError}
    >
      <Providers>{children}</Providers>
    </ApolloProvider>
  );
}

const compose = (providers: React.FC<{ children: React.ReactNode }>[]) =>
  providers.reduce((Prev, Curr) => ({ children }) => {
    const CurrProvider = Prev ? (
      <Prev>
        <Curr>{children}</Curr>
      </Prev>
    ) : (
      <Curr>{children}</Curr>
    );
    return CurrProvider;
  });

const Providers = compose([
  UniversalThemeProvider,
  SafeAreaProvider,
  TamaguiProvider,
  ToastProvider,
  NotificationProvider,
  UploadDocumentProvider,
  PortalProvider,
]);
