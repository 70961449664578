import { formatHsl, interpolate } from "culori/fn";

import { type Colors, makePalette, paletteToColors } from "./colors-utils";

// NOTE: To add additional one-off named colors:
// - Add a hex value below.
// - Add it to `grantedBrandColors` in token-colors.ts.

// Brand colors
export const navyHex = "#002160";
export const lightBlueHex = "#B1E8FF";
export const creamHex = "#FBF7F1";
// Other primary colors - these drive additional palettes.
export const grayHex = "#E2E2E2";
export const greenHex = "#A5F7C1";
export const limeGreenHex = "#DEF5AD";
export const orangeHex = "#FAC093";
export const redHex = "#FA9393";
// Text colors
export const textPrimaryHex = "#0E173B";
export const textSecondaryHex = "#64625F";
export const textTertiaryHex = "#ADA8A0";
// Background colors
export const backgroundPrimaryHex = "#FFFFFF";
export const backgroundSecondaryHex = "#FEFDFB";
export const backgroundTertiaryHex = "#FBF7F1";
// Fills
export const groupedPrimaryHex = "#FAF6FO";
export const groupedSecondaryHex = "#F3F0E9";
// Strokes
export const strokePrimaryHex = "#CCC7BF";
export const strokeSecondaryHex = "#EBE7E0";
export const strokeTertiaryHex = "#FBF7F1";

// Core palette for the application - utilizes a lot of the core specific colors
// to apply to standard Tamagui color indices.
//
// Note that some downstream things rely on the HSL format, so we do the
// conversion there. Performance is not a concern, because all of this is
// codegenned into theme-generated.ts, and not evaluated at runtime!
const grantedInterpolate = interpolate([strokePrimaryHex, navyHex], "oklab");
export const grantedPalette = [
  formatHsl(backgroundPrimaryHex),
  formatHsl(backgroundSecondaryHex),
  formatHsl(backgroundTertiaryHex),
  formatHsl(groupedPrimaryHex),
  formatHsl(groupedSecondaryHex),
  // Only $strokePrimary makes the cut in the palette, but secondary and
  // tertiary are available as global color tokens.
  formatHsl(strokePrimaryHex),
  // 7-9 - blend strokePrimaryHex to navyHex
  // TODO: Craft specific colors for these
  formatHsl(grantedInterpolate(1 / 4)),
  formatHsl(grantedInterpolate(2 / 4)),
  formatHsl(grantedInterpolate(3 / 4)),
  formatHsl(navyHex),
  // TODO: Could make this a different color - mostly we need the extra to
  // support additional dark shades for the general palettes below, but that
  // doesn't really matter for the primary palette.
  formatHsl(navyHex),
  formatHsl(textSecondaryHex),
  formatHsl(textPrimaryHex),
];

export const grantedColors: Colors<"granted"> = {
  granted1: grantedPalette[0]!,
  granted2: grantedPalette[1]!,
  granted3: grantedPalette[2]!,
  granted4: grantedPalette[3]!,
  granted5: grantedPalette[4]!,
  granted6: grantedPalette[5]!,
  granted7: grantedPalette[6]!,
  granted8: grantedPalette[7]!,
  granted9: grantedPalette[8]!,
  granted10: grantedPalette[9]!,
  granted11: grantedPalette[10]!,
  granted12: grantedPalette[11]!,
  granted13: grantedPalette[12]!,
};

/**
 * Maps palette colors to the Granted theme by mapping canonical name to index.
 * This is utilized in templates.ts to build the final mapping. By that point,
 * the palette is expanded with additional transparent colors at each end. These
 * override any other things defined in the base template.
 *
 * Note that the other palettes just use the base template - this only applies
 * to the Granted palette specifically.
 *
 * @param bgIndex Indicates the starting index for the background color.
 * @returns
 */
export const grantedTemplate = (bgIndex: number) => ({
  // Tamagui template keys are used for interoperability with standard
  // components, but the indices may differ slightly. See the base template
  // definition in templates.ts. The comment on each line indicates the design
  // system color name that the index corresponds to.
  background: bgIndex, //            backgroundPrimary
  backgroundHover: bgIndex - 1, //   backgroundPrimary (75% opacity)
  backgroundPress: bgIndex + 2, //   backgroundTertiary
  backgroundFocus: bgIndex + 2, //   backgroundTertiary
  borderColor: bgIndex + 5, //       strokePrimary
  borderColorHover: -bgIndex - 3, // navy
  borderColorPress: -bgIndex - 3, // navy
  borderColorFocus: -bgIndex - 3, // navy
  color: -bgIndex, //                textPrimary
  colorHover: -bgIndex - 3, //       navy
  colorPress: -bgIndex, //           textPrimary
  colorFocus: -bgIndex - 3, //       navy
  colorTransparent: -1,
  placeholderColor: -bgIndex - 1, // textSecondary
  outlineColor: -2,

  // Custom Granted-specific keys, only applied to the Granted theme. These are
  // to allow referring to the standard set of colors by name. Note that the
  // branded colors are instead injected in token-colors.ts.
  backgroundSecondary: bgIndex + 1,
  backgroundTertiary: bgIndex + 2,
  groupedPrimary: bgIndex + 3,
  groupedSecondary: bgIndex + 4,
  strokePrimary: bgIndex + 5,
  textSecondary: -bgIndex - 1,
  // Colors not specifically called out, due to having existing/better options:
  // textPrimary: $color
  // backgroundPrimary: $background
});

// Primary palettes, generated programmatically based on a primary color and a
// background color. These are used when more specific colors and shades are
// needed, or for applying a colored theme to e.g. a pill or button.
export const creamPalette = makePalette(creamHex, backgroundPrimaryHex);
export const cream = paletteToColors("cream", creamPalette);

export const grayPalette = makePalette(grayHex, backgroundPrimaryHex);
export const gray = paletteToColors("gray", grayPalette);

export const greenPalette = makePalette(greenHex, backgroundPrimaryHex);
export const green = paletteToColors("green", greenPalette);

export const lightBluePalette = makePalette(lightBlueHex, backgroundPrimaryHex);
export const lightBlue = paletteToColors("lightBlue", lightBluePalette);

export const limeGreenPalette = makePalette(limeGreenHex, backgroundPrimaryHex);
export const limeGreen = paletteToColors("limeGreen", limeGreenPalette);

export const navyPalette = makePalette(navyHex, backgroundPrimaryHex);
export const navy = paletteToColors("navy", navyPalette);

export const orangePalette = makePalette(orangeHex, backgroundPrimaryHex);
export const orange = paletteToColors("orange", orangePalette);

export const redPalette = makePalette(redHex, backgroundPrimaryHex);
export const red = paletteToColors("red", redPalette);
